import {createAsyncThunk} from '@reduxjs/toolkit'
import {EVENTS_APP_ID} from '@wix/wix-events-commons-statics'
import {ThunkConfig} from './interfaces'

export const navigateToSchedulePage = createAsyncThunk<void, void, ThunkConfig>(
  'NAVIGATE_TO_SCHEDULE_PAGE',
  async (_, {extra: {wixCodeApi}, getState}) => {
    const event = getState().event
    const {query} = wixCodeApi.location
    const queryString = Object.keys(query)
      .map(key => key + '=' + query[key])
      .join('&')
    const target = `${event.slug}${queryString ? `?${queryString}` : ''}`
    const url = await wixCodeApi.site.getSectionUrl({sectionId: 'schedule', appDefinitionId: EVENTS_APP_ID})

    if (wixCodeApi.location.to && url.relativeUrl) {
      const path = `${url.relativeUrl || url.url}/${target}`
      wixCodeApi.location.to(path)
    } else {
      return wixCodeApi.location.navigateToSection({sectionId: 'schedule', state: target, queryParams: ''})
    }
  },
)
