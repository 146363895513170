import {IWidgetControllerConfig} from '@wix/native-components-infra/dist/es/src/types/types'
import {
  createUouBiMiddlewareWithBiParams,
  createUsersBiMiddlewareWithBiParams,
  isRtlLanguage,
  BiParams,
  EVENTS_APP_ID,
} from '@wix/wix-events-commons-statics'
import {ExperimentsBag} from '@wix/wix-experiments'
import {bindActionCreators} from 'redux'
import {setBaseEnvironment, setFormFactor} from '../../commons/actions/environment'
import {updateSiteSettings} from '../../commons/actions/site-settings'
import {decodeInstance} from '../../commons/selectors/instance'
import {initSentry, ErrorMonitor} from '../../commons/services/error-monitor'
import {importResources} from '../../commons/services/i18n'
import {getMultilingualInitialState} from '../../commons/services/multilingual'
import {isMembersEventsPageInstalled} from '../../commons/utils/members-api'
import {createReduxStore, subscribeToStateChanges} from '../../commons/utils/store'
import {getLanguage, getPageInfo, isSSR} from '../../commons/utils/wix-code-api'
import {
  addCalendarMonth,
  closeMonthlyCalendarEvent,
  closeMonthlyCalendarPopup,
  openMonthlyCalendarEvent,
  openMonthlyCalendarPopup,
  resetCalendar,
  subCalendarMonth,
} from '../actions/calendar-layout'
import {updateComponent, updateComponentDraft, updateStyleParams} from '../actions/component'
import {createEvent, shareEvent} from '../actions/event'
import {closeListLayoutItems, openListLayoutItem, widgetLoaded} from '../actions/layout'
import {loadMembersForEvents, openMembersModal} from '../actions/members'
import {navigateToPage} from '../actions/navigate-to-page'
import {resized} from '../actions/resize'
import {updateSettings, updateStyle} from '../actions/sdk'
import * as eventsUou from '../bi/uou-bi-events-map'
import * as eventsUsers from '../bi/users-bi-events-map'
import reducers from '../reducers'
import {sortEvents} from '../reducers/events'
import {isPaidPlansInstalled} from '../services/apps'
import {createWidgetFedopsLogger} from '../services/fedops'
import {Actions, State, StoreExtraArgs} from '../types/state'
import {userEventsLogger} from '../user-events-logger'
import {Api} from '../utils/api'
import {watchInstance} from '../../commons/actions/instance'
import {isResponsiveEditor} from '../../commons/selectors/environment'
import {UserRole} from '../../details-page/constants/constants'

const DSN = 'https://46ad76cef1c840139844ab4624c33fe2@sentry.wixpress.com/291'

export const createWidgetController = async (controller: IWidgetControllerConfig, experiments: ExperimentsBag) => {
  const monitor = initSentry(controller, DSN)
  return Promise.resolve({
    pageReady: monitor.withErrorBoundary(() => pageReady(controller, monitor, experiments)),
  })
}

const pageReady = async (controller: IWidgetControllerConfig, monitor: ErrorMonitor, experiments: ExperimentsBag) => {
  const fedopsLogger = createWidgetFedopsLogger(controller)

  const serverApi = new Api(controller)
  const {staticsBaseUrl} = controller.appParams.baseUrls
  const language = getLanguage(controller.wixCodeApi)
  const [translations, pageInfo, pageUrl, store] = await Promise.all([
    importResources(['demo-data', 'accessibility', 'widget'], language, staticsBaseUrl),
    getPageInfo(controller.wixCodeApi),
    controller.wixCodeApi.site.getSectionUrl({
      sectionId: 'events',
      appDefinitionId: EVENTS_APP_ID,
    }),
    createStore(controller, serverApi, experiments),
  ])
  const state = store.getState()

  const props = {
    pageUrl,
    pageInfo,
    state,
    translations,
    actions: exportedActions({
      store,
      appLoaded: fedopsLogger.onAppLoaded,
      monitor,
    }),
    isRTL: isRtlLanguage(getLanguage(controller.wixCodeApi)),
  }

  if (state.membersEnabled) {
    serverApi.onLogin(() => {
      store.dispatch(loadMembersForEvents() as any)
    })
  }

  controller.setProps(props)

  if (isSSR(controller.wixCodeApi)) {
    fedopsLogger.onSSRPageReady()
  }
}

const createBiMiddleware = (biParams: BiParams) => [
  createUsersBiMiddlewareWithBiParams(biParams, eventsUsers),
  createUouBiMiddlewareWithBiParams(biParams, eventsUou),
]

const createStore = async (controller: IWidgetControllerConfig, serverApi: Api, experiments: ExperimentsBag) => {
  const {wixCodeApi, platformAPIs, appParams, compId, config} = controller
  const {initialData} = await getInitialData(serverApi, controller)

  const biMiddleware = createBiMiddleware({
    wixCodeApi,
    platformAPIs,
    appParams,
    compId,
    user: {
      aid: initialData.instance.aid,
      uid: initialData.instance.uid,
    },
  })

  const userEventsLoggerMiddleware = userEventsLogger({wixCodeApi})

  const store = createReduxStore<State, StoreExtraArgs>({
    reducers,
    initialData: {
      ...initialData,
      experiments,
    },
    extraArguments: {
      serverApi,
      wixCodeApi,
      compId,
    },
    middleware: [...biMiddleware, userEventsLoggerMiddleware],
  })

  store.dispatch(updateStyleParams(config.style.styleParams))
  store.dispatch(setBaseEnvironment() as any)

  watchInstance(controller, store.dispatch)
  subscribeToStateChanges(controller, store)

  return store
}

const getInitialData = async (
  serverApi: Api,
  {appParams, wixCodeApi, config: controllerConfig}: IWidgetControllerConfig,
): Promise<{initialData: Partial<State>}> => {
  const [membersEnabled, paidPlansInstalled] = await Promise.all([
    isMembersEventsPageInstalled(wixCodeApi),
    isPaidPlansInstalled(wixCodeApi),
  ])
  const {
    component: {events, config, id},
    siteSettings,
    demoEvents,
  } = await serverApi.getAppData(membersEnabled, paidPlansInstalled, isResponsiveEditor(controllerConfig))
  const instance = appParams.instance

  return {
    initialData: {
      events: sortEvents(events),
      siteSettings,
      demoEvents,
      multilingual: getMultilingualInitialState(wixCodeApi),
      component: {
        ...config,
        id,
      },
      membersEnabled,
      owner: wixCodeApi.user.currentUser.role === UserRole.ADMIN,
      instance: {
        instance,
        ...decodeInstance(instance),
      },
    },
  }
}

const exportedActions = ({store, appLoaded, monitor}) => {
  const dispatchActions = {
    addCalendarMonth,
    subCalendarMonth,
    resetCalendar,
    createEvent,
    updateSiteSettings,
    updateComponent,
    updateComponentDraft,
    navigateToPage,
    updateStyle,
    updateStyleParams,
    updateSettings,
    setBaseEnvironment,
    openListLayoutItem,
    closeListLayoutItems,
    openMonthlyCalendarPopup,
    closeMonthlyCalendarPopup,
    openMonthlyCalendarEvent,
    closeMonthlyCalendarEvent,
    widgetLoaded,
    openMembersModal,
    shareEvent,
    resized,
    setFormFactor,
  }

  const actions: Actions = {
    ...bindActionCreators(dispatchActions, store.dispatch),
    appLoaded,
  }

  return monitor.bindActions(actions)
}
