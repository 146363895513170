import {IWidgetControllerConfig} from '@wix/native-components-infra/dist/src/types/types'
import {IWixStyleParams} from '@wix/native-components-infra/dist/src/types/wix-sdk'
import {
  BiParams,
  createUouBiMiddlewareWithBiParams,
  isRtlLanguage,
  PAID_PLANS_APP_DEF_ID,
  PAID_PLANS_SECTION_ID,
  isTicketed,
  EVENTS_APP_ID,
} from '@wix/wix-events-commons-statics'
import {ExperimentsBag} from '@wix/wix-experiments'
import {bindActionCreators, Store} from 'redux'
import {setBaseEnvironment, setFormFactor} from '../../commons/actions/environment'
import {watchInstance} from '../../commons/actions/instance'
import {decodeInstance} from '../../commons/selectors/instance'
import {ErrorMonitor, initSentry} from '../../commons/services/error-monitor'
import {importResources} from '../../commons/services/i18n'
import {getMultilingualInitialState} from '../../commons/services/multilingual'
import {isMembersEventsPageInstalled} from '../../commons/utils/members-api'
import {createReduxStore, subscribeToStateChanges} from '../../commons/utils/store'
import {getLanguage, getPageUrl, isSSR} from '../../commons/utils/wix-code-api'
import {
  checkout,
  clearCheckout,
  editStep,
  getDiscount,
  handleNextStep,
  nextFormClicked,
  setExpandedTicketIndex,
  setTicketDetails,
  setUseBuyerDetails,
  setValidPaymentAdded,
  submitCheckoutStep,
} from '../actions/checkout'
import {toggleAgreePolicies, openPolicyModal} from '../actions/policies'
import {getCheckoutOptions} from '../actions/checkout-options'
import {publishComponentSettings, updateStyleParams} from '../actions/component'
import {applyCoupon, resetCouponCode, setCouponCode} from '../actions/coupon'
import {getMembers, shareEvent} from '../actions/event'
import {clearInvoice} from '../actions/invoice'
import {detailsPageLoaded, formPageLoaded, thankYouPageLoaded, ticketedThankYouPageLoaded} from '../actions/loaded'
import {
  addLoginListener,
  ensureLoginForMembersOnly,
  fetchCurrentMember,
  openMembersModal,
  promptLogin,
} from '../actions/members'
import {
  openCantCompletePaymentModal,
  openCheckoutUnavailable,
  openTicketsDetailsModal,
  openTimeExpiredModal,
  closeNativeModal,
} from '../actions/modals'
import {
  addLocationListener,
  handleInitialNavigation,
  navigate,
  navigateToChangeRsvp,
  navigateToDetails,
  navigateToForm,
  navigateToMainPage,
  navigateToOrder,
  navigateToTicketsPicker,
  navigateViaEditor,
} from '../actions/navigation'
import {downloadTickets} from '../actions/order-success'
import {applyPlan, getPlanList, openMembershipPicker} from '../actions/paid-plans'
import {paymentMethodSelected, placeOrderButtonClicked} from '../actions/payment'
import {placeOrder, updateOrder} from '../actions/placed-order'
import {registrationButtonClicked} from '../actions/registration'
import {cancelReservation, reserveTickets} from '../actions/reservation'
import {
  changeMemberRsvp,
  getMemberRsvp,
  handleRSVP,
  resetRsvpError,
  sendRsvp,
  updateRsvp,
  submitRsvpStep,
  handleNextRsvpStep,
  editRsvpStep,
  changeResponse,
} from '../actions/rsvp'
import {selectTicket, unselectTicket} from '../actions/selected-tickets'
import {updateSettings} from '../actions/settings'
import {collapseDescription, expandDescription} from '../actions/tickets-picker'
import * as eventsUou from '../bi/uou-bi-events-map'
import {DetailsPageProps} from '../components/app/interfaces'
import reducers from '../reducers'
import {getDemoEvent} from '../services/demo-event'
import {createDetailsPageFedopsLogger} from '../services/fedops'
import {Actions, FedopsLogger, State, StoreExtraArgs} from '../types'
import {userEventsLogger} from '../user-events-logger'
import {Api} from '../utils/api'
import {parseLocation} from '../utils/navigation'
import {isResponsiveEditor} from '../../commons/selectors/environment'
import {navigateToSchedulePage} from '../actions/schedule'

const DSN = 'https://588ca41dab294885b034d58caffde32b@sentry.wixpress.com/310'

export const createDetailsPageController = async (controller: IWidgetControllerConfig, experiments: ExperimentsBag) => {
  const monitor = initSentry(controller, DSN)
  return Promise.resolve({
    pageReady: monitor.withErrorBoundary(() => pageReady(controller, monitor, experiments)),
  })
}

const pageReady = async (controller: IWidgetControllerConfig, monitor: ErrorMonitor, experiments: ExperimentsBag) => {
  const {wixCodeApi} = controller
  const {staticsBaseUrl} = controller.appParams.baseUrls
  const {onAppLoaded, onSSRPageReady, ...fedopsLogger} = createDetailsPageFedopsLogger(controller)
  const language = getLanguage(wixCodeApi)
  const translationsPromise = importResources(['page', 'demo-data'], language, staticsBaseUrl)
  const ssr = isSSR(controller.wixCodeApi)

  const serverApi = new Api(controller)
  const [pageUrl, initialData, paidPlansEnabled, membersAreaEnabled] = await Promise.all([
    getPageUrl(wixCodeApi),
    getInitialData(serverApi, controller, controller.config.style.styleParams),
    wixCodeApi.site.isAppSectionInstalled({
      appDefinitionId: PAID_PLANS_APP_DEF_ID,
      sectionId: PAID_PLANS_SECTION_ID,
    }),
    isMembersEventsPageInstalled(wixCodeApi),
  ])
  const store = createStore(
    controller,
    fedopsLogger,
    experiments,
    {...initialData, membersAreaEnabled, paidPlansEnabled},
    serverApi,
  )

  const [translations] = await Promise.all([
    translationsPromise,
    store.dispatch(setBaseEnvironment() as any),
    store.dispatch(getMemberRsvp() as any),
    store.dispatch(fetchCurrentMember() as any),
  ])

  store.dispatch(addLoginListener() as any)

  watchInstance(controller, store.dispatch)

  await store.dispatch(handleInitialNavigation() as any)

  addLocationListener(controller.wixCodeApi, store)
  subscribeToStateChanges(controller, store)

  const actions = exportedActions(store, onAppLoaded, monitor)

  const props: DetailsPageProps = {
    state: store.getState(),
    actions,
    isRTL: isRtlLanguage(language),
    staticsBaseUrl: controller.appParams.baseUrls.staticsBaseUrl,
    pageUrl,
    translations,
  }

  controller.setProps(props)

  if (!ssr) {
    store.dispatch(getMembers() as any)

    if (paidPlansEnabled) {
      store.dispatch(getPlanList() as any)
    }

    if (isTicketed(store.getState().event)) {
      store.dispatch(getCheckoutOptions() as any)
    }
  }

  if (ssr) {
    onSSRPageReady()
  }
}

const createBiMiddleware = (biParams: BiParams) => [createUouBiMiddlewareWithBiParams(biParams, eventsUou)]

const createStore = (
  controller: IWidgetControllerConfig,
  fedopsLogger: FedopsLogger,
  experiments: ExperimentsBag,
  initialData,
  serverApi,
) => {
  const {wixCodeApi, compId, platformAPIs, appParams} = controller

  const biMiddleware = createBiMiddleware({
    wixCodeApi,
    platformAPIs,
    appParams,
    compId,
    user: {
      aid: initialData.instance.aid,
      uid: initialData.instance.uid,
    },
  })

  const userEventsLoggerMiddleware = userEventsLogger({wixCodeApi})

  return createReduxStore<State, StoreExtraArgs>({
    reducers,
    initialData: {...initialData, ...(controller as any).testState, experiments},
    extraArguments: {serverApi, wixCodeApi, compId, platformAPIs, fedopsLogger},
    middleware: [...biMiddleware, userEventsLoggerMiddleware],
  })
}

const getInitialData = async (
  serverApi: Api,
  controller: IWidgetControllerConfig,
  styleParams: IWixStyleParams,
): Promise<Partial<State>> => {
  const {wixCodeApi, appParams, config} = controller
  const navigation = parseLocation(wixCodeApi)
  const {slug} = navigation
  const responsiveEditor = isResponsiveEditor(config)
  const schedulePageInstalled = await wixCodeApi.site.isAppSectionInstalled({
    appDefinitionId: EVENTS_APP_ID,
    sectionId: 'schedule',
  })
  const [{event, component, siteSettings, demoEvents, tickets, policies, schedule}] = await Promise.all([
    serverApi.getData(slug, responsiveEditor, schedulePageInstalled),
  ])
  const instance = appParams.instance

  return {
    event: !event && demoEvents ? getDemoEvent(demoEvents, slug, responsiveEditor) : event,
    siteSettings,
    demoEvents,
    tickets,
    schedule,
    multilingual: getMultilingualInitialState(wixCodeApi),
    navigation,
    component: {
      id: component.id,
      settings: {
        ...component.config.settings,
        ...styleParams.numbers,
        ...styleParams.booleans,
      },
    },
    instance: {
      instance,
      ...decodeInstance(instance),
    },
    policies: {
      agreed: false,
      ...policies,
    },
  }
}

const exportedActions = (store: Store, appLoaded, monitor): Actions => {
  const dispatchActions = {
    navigateToForm,
    navigateToMainPage,
    detailsPageLoaded,
    formPageLoaded,
    thankYouPageLoaded,
    ticketedThankYouPageLoaded,
    changeMemberRsvp,
    registrationButtonClicked,
    updateStyleParams,
    setCouponCode,
    resetCouponCode,
    resetRsvpError,
    sendRsvp,
    updateRsvp,
    reserveTickets,
    selectTicket,
    unselectTicket,
    ensureLoginForMembersOnly,
    expandDescription,
    collapseDescription,
    getMembers,
    getPlanList,
    openMembershipPicker,
    promptLogin,
    navigateToTicketsPicker,
    openCheckoutUnavailable,
    updateOrder,
    placeOrder,
    paymentMethodSelected,
    placeOrderButtonClicked,
    navigateToDetails,
    nextFormClicked,
    openTicketsDetailsModal,
    cancelReservation,
    applyCoupon,
    applyPlan,
    handleRSVP,
    checkout,
    submitCheckoutStep,
    editStep,
    openTimeExpiredModal,
    openCantCompletePaymentModal,
    setValidPaymentAdded,
    navigate,
    navigateViaEditor,
    setUseBuyerDetails,
    setTicketDetails,
    clearInvoice,
    navigateToOrder,
    navigateToChangeRsvp,
    setExpandedTicketIndex,
    handleNextStep,
    openMembersModal,
    updateSettings,
    publishComponentSettings,
    clearCheckout,
    getDiscount,
    downloadTickets,
    shareEvent,
    toggleAgreePolicies,
    openPolicyModal,
    submitRsvpStep,
    handleNextRsvpStep,
    editRsvpStep,
    changeResponse,
    setFormFactor,
    closeNativeModal,
    navigateToSchedulePage,
  }

  const actions: Actions = {
    ...bindActionCreators(dispatchActions, store.dispatch),
    appLoaded,
  }

  return monitor.bindActions(actions)
}
