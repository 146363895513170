import {createReducer} from '@reduxjs/toolkit'
import {ListScheduleItemsResponse} from '@wix/ambassador-wix-events-agenda/types'

const defaultState = {
  facets: {},
  items: [],
  limit: 0,
  offset: 0,
  total: 0,
} as ListScheduleItemsResponse

export const schedule = createReducer(defaultState, () => {})
